<template>
  <div>
    <div class="modal fade" id="modal-guia_individual">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Guía Individual</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModal"
              id="close-modal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="form-group col-md-6">
                <label>Número De Guía</label>
                <input
                  type="number"
                  class="form-control form-control-sm"
                  id="num_guia"
                  placeholder="Número Guía"
                  v-model="form.numero"
                  :class="$v.form.numero.$invalid ? 'is-invalid' : 'is-valid'"
                  @blur="validaGuia()"
                />
              </div>
              <div class="form-group col-md-6">
                <label>Dígito Verificación</label>
                <input
                  type="number"
                  class="form-control form-control-sm"
                  id="dv"
                  placeholder="Dígito Verificación"
                  v-model="form.digito_verificacion"
                />
              </div>
              <div class="form-group col-md-6">
                <label>Sitio</label>
                <v-select
                  v-model="sitio"
                  placeholder="Nombre"
                  label="nombre"
                  :options="$parent.listasForms.sitios"
                  @input="selectSitio()"
                  class="form-control form-control-sm p-0"
                  :class="$v.form.sitio_id.$invalid ? 'is-invalid' : 'is-valid'"
                ></v-select>
              </div>
              <div class="form-group col-md-6">
                <label>Estado</label>
                <select
                  class="form-control form-control-sm"
                  v-model="form.estado"
                  :class="$v.form.estado.$invalid ? 'is-invalid' : 'is-valid'"
                  disabled
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="estado in $parent.listasForms.estados"
                    :key="estado.numeracion"
                    :value="estado.numeracion"
                  >
                    {{ estado.descripcion }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-12">
                <label>Tipo Empresa</label>
                <select
                  class="form-control form-control-sm"
                  v-model="form.tipo_empresa"
                  :class="
                    $v.form.tipo_empresa.$invalid ? 'is-invalid' : 'is-valid'
                  "
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="tip in $parent.listasForms.tipoEmpresas"
                    :key="tip.numeracion"
                    :value="tip.numeracion"
                  >
                    {{ tip.descripcion }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-12">
                <label>Tipo Producto</label>
                <select
                  class="form-control form-control-sm"
                  v-model="form.tipo_producto"
                  :class="
                    $v.form.tipo_producto.$invalid ? 'is-invalid' : 'is-valid'
                  "
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="tipo_producto in $parent.listasForms.tipo_productos"
                    :key="tipo_producto.numeracion"
                    :value="tipo_producto.numeracion"
                  >
                    {{ tipo_producto.descripcion }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div
            class="modal-footer justify-content-between"
            v-if="
              $store.getters.can('hidrocarburos.guias.create') &&
                !$v.form.$invalid
            "
          >
            <button type="button" class="btn btn-primary" @click="save()">
              Guardar
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import vSelect from "vue-select";

export default {
  name: "GuiaIndividual",
  components: {
    vSelect,
  },

  data() {
    return {
      sitio: {},
      form: {
        numero: null,
        digito_verificacion: null,
        sitio_id: null,
        tipo_empresa: null,
        tipo_producto: null,
        estado: null,
      },
    };
  },
  validations: {
    form: {
      numero: {
        required,
        maxLength: maxLength(12),
        minLength: minLength(10),
      },
      digito_verificacion: {
        maxLength: maxLength(1),
        minLength: minLength(1),
      },
      sitio_id: {
        required,
      },
      estado: {
        required,
      },
      tipo_empresa: {
        required,
      },
      tipo_producto: {
        required,
      },
    },
  },
  methods: {
    selectSitio() {
      this.form.sitio_id = "";
      if (this.sitio) {
        this.form.sitio_id = this.sitio.id;
      }
    },

    // Método de guardar
    save() {
      if (!this.$v.form.$invalid) {
        this.cargando = true;
        axios
          .post("/api/hidrocarburos/guias/saveGuiaIn", this.form)
          .then((response) => {
            this.cargando = false;
            this.$refs.closeModal.click();
            this.resetForm();
            this.$parent.getIndex(); //Lista de nuevo
            this.$swal({
              icon: "success",
              title: "Se guardo exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrió un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    // Método limpiar formulario
    resetForm() {
      this.sitio = {};
      this.form = {
        numero: null,
        digito_verificacion: null,
        sitio_id: null,
        estado: 1,
        tipo_empresa: null,
      };
    },

    //  Método guía existente
    validaGuia() {
      let params = {
        numero: this.form.numero,
      };
      if (this.form.numero != null) {
        axios
          .get("/api/hidrocarburos/guias/listaSelect", {
            params,
          })
          .then((response) => {
            if (response.data.length > 0) {
              this.form.numero = null;
              this.$swal({
                icon: "error",
                title: "La guía digitada ya se encuentra creada..",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            }
          });
      }
    },
  },
};
</script>
